import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CongressService } from "../../../providers/congress.service";
// import  Congress  from '../../../models/Congress';

import { ActivatedRoute } from "@angular/router";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { ajax, apiURL } from "../../../providers/helpers";

@Component({
  selector: "app-interviews",
  templateUrl: "./interviews.component.html",
  styleUrls: ["./interviews.component.css"],
})
export class InterviewsComponent implements OnInit {
  faPlay = faPlay;

  congress_interviews = [];
  congress_interview_intro = { description: "" };
  congress: any = {};
  pdf_id = null;

  constructor(
    private sanitizer: DomSanitizer,
    private congressService: CongressService,
    private activatedRoute: ActivatedRoute
  ) {}

  loadInterviews() {
    const url = apiURL("congress_interview", "lire-details", this.congress.id);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.congress_interviews = JSON.parse(res).records.map((interview) => {
          interview.content = this.sanitizer.bypassSecurityTrustResourceUrl(
            interview.content
          );
          return interview;
        });
      },
      false
    );

    const url2 = apiURL(
      "congress_interview_intro",
      "read_one_by_congress_id",
      this.congress.id
    );
    ajax(
      url2,
      "GET",
      null,
      (res: any) => {
        this.congress_interview_intro = JSON.parse(res).records;
      },
      false
    );
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.activatedRoute.params.subscribe((data) => {
      this.congressService
        .init("interviews", data.id)
        .then((data: any) => {
          this.congress = data.congress;
          this.loadInterviews();
        })
        .catch((err) => {
          // console.log(err);
        });
    });
  }

  photoURL() {
    return this.sanitizer.sanitize(5, this.congress_interviews[1].content);
  }
}
