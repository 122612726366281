import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-main-template",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
export class MainTemplateComponent implements OnInit {
  @Input() navItems: any;
  @Input() title: string;

  constructor(private router: Router) {}

  goToNavItem(navItem = { url: "" }) {
    this.router.navigate([navItem.url]);
  }

  ngOnInit() {}
}
