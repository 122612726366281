import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  faLock,
  faLockOpen,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { NavData } from "../../providers/NavData";
import { getStoredUser, updateStoredUser } from "../../providers/user-service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  @Input() selectedIndex: number;

  faUnlock = faLockOpen;
  faLock = faLock;
  faSearch = faSearch;

  navItems;
  user;
  popupText: String;
  showPopup;
  displayLoginPopup = false;

  constructor(private router: Router) {}

  homeRedirect() {
    this.router.navigate(["/accueil"]);
  }

  loginRedirect() {
    this.router.navigate(["/login"]);
  }

  selectNavItem() {
    window.scroll(0, 0);
  }

  logout() {
    updateStoredUser({});
    this.user = {};

    this.router.navigate(["/accueil"]);
    this.popupText = `Vous êtes à présent déconnecté(e) !`;
    this.showPopup = true;
  }

  ngOnInit() {
    this.navItems = NavData.getNavItems();
    // // console.log(this.navItems);
    this.user = getStoredUser();
  }
}
