import { Component, OnInit } from "@angular/core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  AVANTAGES_ADHESION_PHYSIC_PDF_NAME,
  PROCEDURE_ADHESION_PHYSIC,
} from "../../../providers/global";
import { ajax, apiURL } from "../../../providers/helpers";
import { getStoredUser } from "../../../providers/user-service";

@Component({
  selector: "app-adhesion-physique",
  templateUrl: "./adhesion-physique.component.html",
  styleUrls: ["./adhesion-physique.component.css"],
})
export class AdhesionPhysiqueComponent implements OnInit {
  pdf_id;
  showAdhesionPhysicForm = null;
  user: any = {};

  popupText =
    "Vous devez vous connecter à votre compte pour accéder au formulaire d'adhésion. Si vous n'en avez pas, créez votre espace en ligne via le bouton \"connexion\" en 1 minute.";
  faArrowRight = faArrowRight;
  showPopup = false;

  constructor() {}

  setPdfName(pdfName) {
    switch (pdfName) {
      case "procedure":
        return PROCEDURE_ADHESION_PHYSIC;
        break;
      case "avantages":
        return AVANTAGES_ADHESION_PHYSIC_PDF_NAME;
    }
  }

  showPdf(pdfName) {
    const url = apiURL("pdf", "read_one_id", "");
    const name = this.setPdfName(pdfName);
    // console.log(name);

    ajax(
      url,
      "POST",
      { name },
      (res) => {
        let response: any = res;
        response = JSON.parse(response);
        this.pdf_id = response.records.id;
      },
      false
    );
  }

  openAdhesionPersonnePhysiqueForm() {
    this.showAdhesionPhysicForm = true;
    document.body.classList.add("modal-open");
  }

  closeAdhesionPersonnePhysiqueForm() {
    this.showAdhesionPhysicForm = null;
    document.body.classList.remove("modal-open");
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.user = getStoredUser();
    // console.log(this.user)
  }
}
