import { Component, OnInit } from "@angular/core";
import { CongressService } from "../../../providers/congress.service";
import { ajax, apiURL } from "../../../providers/helpers";
// import  Congress  from '../../../models/Congress';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-infos",
  templateUrl: "./infos.component.html",
  styleUrls: ["./infos.component.css"],
})
export class InfosComponent implements OnInit {
  navItem = { icon: "acts" };
  congress: any = {};
  infos: any[];
  sideNav2;
  user: any = {};
  pdf_id = null;

  constructor(
    private congressService: CongressService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  getInfos() {
    const url = apiURL("congress_info", "lire", this.congress.id);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.infos = JSON.parse(res).records;
      },
      false
    );
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.activatedRoute.params.subscribe((data) => {
      this.congressService
        .init("infos", data.id)
        .then((data: any) => {
          this.user = data.user;
          this.congress = data.congress;
          this.sideNav2 = data.sideNav2;
          this.getInfos();
        })
        .catch((err) => {
          // console.log(err);
        });
    });
  }
}
