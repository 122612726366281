import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ajax, apiURL } from "../../../providers/helpers";
import { getStoredUser } from "../../../providers/user-service";

@Component({
  selector: "app-congress-resume",
  templateUrl: "./congress-resume.component.html",
  styleUrls: ["./congress-resume.component.css"],
})
export class CongressResumeComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    toolbarHiddenButtons: [
      ["fontName"],
      [
        "customClasses",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
      ],
    ],
  };

  faArrowRight = faArrowRight;
  congress_id;
  congress;
  review = "";
  user: any = {};
  showArrow;
  displayNoResume;
  popupText;
  showPopup = false;
  displayLoginPopup = false;

  constructor(private router: Router) {}

  closePopup() {
    this.showPopup = false;
    this.router.navigate(["/espace_perso/accueil"]);
  }

  submitCongressSumup() {
    const urlResume = apiURL("congress_review", "create", "");
    ajax(
      urlResume,
      "POST",
      {
        content: this.review,
        congress_id: this.congress.id,
        user_id: this.user.id,
      },
      (res: any) => {
        // res = JSON.parse(res);

        this.popupText =
          "<h2>Merci pour votre contribution!</h2><p>Votre résumé a bien été soumis aux équipes de la SFSE. Les membres du conseil scientifique du congrès évalueront votre proposition dans les meilleurs délais</p>";
        this.showPopup = true;
      },
      false
    );
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.displayNoResume = false;
    window.setTimeout(() => {
      this.displayNoResume = true;
    }, 1000);
    this.user = getStoredUser();
    const url = apiURL("congress", "read_reviewable", "");

    ajax(
      url,
      "GET",
      null,
      (res) => {
        let response: any = res;
        response = JSON.parse(response);
        this.congress = response.records;
        if (this.congress && this.congress.img_data) {
          this.congress.img_data =
            "data:image/jpg;base64," + this.congress.img_data;
        }

        const urlResume = apiURL("congress_review_model", "lire", "");
        ajax(
          urlResume,
          "GET",
          null,
          (res: any) => {
            res = JSON.parse(res);
            this.review = res.content;

            if (!this.user || !this.user.id) {
              this.showArrow = true;
              window.setTimeout(() => {
                this.showArrow = false;
              }, 5000);
            }
          },
          false
        );
      },
      false
    );
  }
}
