import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import {
  IMG_FOLDER_SUBMITED_ID,
  PDF_FOLDER_SUBMITED_ID,
} from "../../../providers/global";
import {
  COUNTRIES_WITH_REGIONS,
  ajax,
  apiURL,
  resizeImage,
  timestampToDate,
} from "../../../providers/helpers";

import { getStoredUser } from "../../../providers/user-service";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FIELD_COSTRUCTORS } from "../../../providers/form-fields";

import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-add-new-item-form",
  templateUrl: "./add-new-item-form.component.html",
  styleUrls: ["./add-new-item-form.component.css"],
})
export class AddNewItemFormComponent implements OnInit {
  faTimes = faTimes;

  countries_with_regions = COUNTRIES_WITH_REGIONS;
  regions = [];
  title = "";
  popupText = "";
  showPopup = false;
  showSubmitPdfBtn;
  pdfFormData;
  imgFormData;
  newPdfSrc;
  loading;
  loadingText;
  country = [];

  user: any = { mail: "" };

  config: Object = {
    charCounterCount: false,
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'indent',
        // 'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        "fontName",
      ],
      [
        // 'fontSize',
        // 'textColor',
        // 'backgroundColor',
        // 'customClasses',
        // 'link',
        // 'unlink',
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        "toggleEditorMode",
      ],
    ],
  };

  constructor() {}

  @ViewChild("pdfViewerOnDemand", { static: true }) public pdfViewerOnDemand;

  @Input() table_name: String;
  @Output() close = new EventEmitter<{}>();

  formFields = <any>[];
  item = <any>{};
  authorEmail = "";

  chosenImg;
  chosenImgSrc;
  resizedImg;
  resizedImgSrc;
  selectedOptions;

  submitPdf(e) {
    e.preventDefault();
    this.pdfFormData = new FormData(e.target);
    this.showSubmitPdfBtn = false;
    this.postPDF();
  }

  postPDF() {
    const now = timestampToDate(new Date().getTime(), true);
    const userMail = this.user.mail || "non_inscrit";
    let pdfTitle =
      userMail + "_" + this.title + "_" + now + "_" + this.item.title;

    this.pdfFormData.append("folderId", PDF_FOLDER_SUBMITED_ID);
    this.pdfFormData.append("postName", pdfTitle);

    const pdfURL = apiURL("pdf", "create", "");
    ajax(
      pdfURL,
      "POST",
      this.pdfFormData,
      (res) => {
        this.item.pdf_id = JSON.parse(res).newPdfId;
      },
      true
    );
  }

  setPdfToUpload(e) {
    this.showSubmitPdfBtn = false;
    this.pdfFormData = null;
    const pdf = e.target.files[0];

    if (pdf.type != "application/pdf") {
      this.popupText = "Format de pdf non reconnu !";
      this.showPopup = true;
      return;
    }
    this.showSubmitPdfBtn = true;
  }

  submitImg(e) {
    e.preventDefault();
    this.imgFormData = new FormData(e.target);
    this.postImg();
  }

  postImg() {
    this.loadingText = "Envoi de votre image ...";
    this.loading = true;
    const now = new Date().getTime();
    const postDate = timestampToDate(now, true);
    const userMail = this.user.mail || "";
    const title = this.item.title || "";
    const customName = `${postDate}_${this.title}_${title}_${userMail}`;

    this.imgFormData.append("folderId", IMG_FOLDER_SUBMITED_ID);
    this.imgFormData.append("type", this.chosenImg.type);
    this.imgFormData.append("img", this.resizedImg);
    this.imgFormData.append("custom_name", customName);

    const imgURL = apiURL("img", "create", "");
    ajax(
      imgURL,
      "POST",
      this.imgFormData,
      (res) => {
        this.item.img_id = JSON.parse(res).newThumbnailId;
        this.item.img_id;
        this.loading = false;
        this.chosenImgSrc = null;
        this.popupText = "Votre image a bien été envoyée !";
        this.showPopup = true;
      },
      true,
      (err) => {
        err;
        this.loading = false;
        this.popupText = JSON.parse(err).message;
        this.showPopup = true;
      }
    );
  }

  setImgToUpload(e) {
    this.chosenImg = null;
    this.chosenImgSrc = null;
    this.resizedImgSrc = null;
    this.resizedImg = null;
    this.imgFormData = null;

    const chosenImg = e.target.files[0];
    chosenImg;
    const fileSize = chosenImg.size / 1000000;
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (allowedTypes.indexOf(chosenImg.type) < 0) {
      this.popupText =
        "Vous ne pouvez ajouter que des images au format .png ou .jpeg !";
      this.showPopup = true;
    } else if (chosenImg.size > 2000000) {
      this.popupText = `Image trop volumineuse ! Maximum supporté : 2Mo, taille de votre image : ${fileSize}Mo`;
      this.showPopup = true;
    } else {
      this.chosenImg = chosenImg;
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(this.chosenImg);

      reader.onloadend = (e: any) => {
        this.chosenImgSrc = reader.result;

        resizeImage(e, this.chosenImg.name, this.chosenImg.type, 0.2, 50).then(
          (resizedImg: File) => {
            this.resizedImg = resizedImg;

            reader.readAsDataURL(this.resizedImg);
            reader.onloadend = () => {
              this.resizedImgSrc = reader.result;
            };
          }
        );
      };
    }
  }

  selectedCountryHasChanged() {
    this.item.region_id = "";
    if (this.countries_with_regions[this.item.country_id]) {
      const url = apiURL("region", "lire", this.item.country_id);
      ajax(
        url,
        "GET",
        null,
        (res: any) => {
          this.regions = JSON.parse(res).records || [];
        },
        false
      );
    } else {
      this.regions = [];
    }
  }

  addNewMultiOptions($event) {
    this.selectedOptions = $event.options;
  }

  allRequiredFieldsAreFilled() {
    const errors = [];
    this.formFields.forEach((field) => {
      const fieldValue = String(this.item[field.model]).trim();
      if (
        field.required &&
        (fieldValue.length < 1 || fieldValue === "undefined")
      ) {
        errors.push(field.text);
      }
    });

    if (!this.authorEmail) {
      errors.push("Votre adresse mail");
    }

    if (errors.length > 0) {
      this.popupText =
        "<h2>Liste des champs à remplir ou à corriger</h2>" +
        errors.join("<br>");
      this.showPopup = true;
    }
    return errors.length === 0;
  }

  sendMail() {
    const rubric_names = {
      event: "Manifestations francophones",
      useful_doc: "Documentation",
      project_appeal: "Appel à projets",
      formation: "Formations",
      job_announce: "Annonces emplois",
      web_tool: "Outils web",
      public_politic: "Politiques publiques",
      infos_divers: "Infos divers",
    };
    const index = String(this.table_name);

    const data: any = {
      body: "user_publication",
      subject: "Nouvelle publication d'un utilisateur",
      addresses: "missions.rp@sfse.org",
      body_infos: rubric_names[index],
    };
    const url = apiURL("-mailer_service", "phpmailer", "");
    ajax(
      url,
      "POST",
      data,
      (res: any) => {
        this.completionMessage();
      },
      false,
      (err) => {
        // console.log(err);
      }
    );
  }

  completionMessage() {
    this.popupText =
      "<p>Votre demande d’ajout a bien été prise en compte.<br>Nous la traiterons dans les plus brefs délais.</p>";
    this.showPopup = true;
    setTimeout(() => {
      this._close();
    }, 3500);
  }

  _close() {
    window.scroll(0, 0);
    this.close.emit();
  }

  _addItem() {
    if (!this.allRequiredFieldsAreFilled()) {
      return;
    }

    const data = { ...this.item };
    data.title += ` (publié par : ${this.authorEmail})`;
    const url = apiURL(this.table_name, "create", "");
    ajax(
      url,
      "POST",
      data,
      (res: any) => {
        const id = JSON.parse(res).records.id;
        if (!this.selectedOptions || this.selectedOptions.length < 1) {
          this.completionMessage();
          return;
        }

        const thematicUrl = apiURL(this.table_name + "_thematic", "update", "");
        const options = {
          thematic_ids: this.selectedOptions,
        };
        options[this.table_name + "_id"] = id;

        ajax(
          thematicUrl,
          "POST",
          options,
          (res: any) => {
            this.sendMail();
          },
          false
        );
      },
      false
    );
  }

  ngOnInit() {
    this.user = getStoredUser();
    const selects = [];
    switch (this.table_name) {
      case "event":
        this.formFields = [
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "title",
            "Nom de l'événement",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("img"),
          new FIELD_COSTRUCTORS.Select(
            "type_id",
            "Type d'événement",
            "event_type",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "inputDate",
            "debut",
            "Date de début",
            "",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "inputDate",
            "end",
            "Date de fin",
            "",
            true
          ),
          new FIELD_COSTRUCTORS.Country(true),
          new FIELD_COSTRUCTORS.InputCheckbox("input", "city", "Ville"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "place",
            "Lieu/Adresse",
            true
          ),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "organization",
            "Organisme(s) organisateur(s)",
            false,
            "exemple: organisme_1, organisme_2"
          ),
          new FIELD_COSTRUCTORS.Select("pricing_id", "Tarification", "pricing"),
          new FIELD_COSTRUCTORS.ImgPdf("pdf"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "link",
            "Lien (si pas de PDF)"
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "textarea",
            "description_short",
            "Description courte",
            "Description courte (maximum 200 caractères)",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "wysiwyg",
            "description",
            "Description complète",
            "Description complète",
            true
          ),
          // new FIELD_COSTRUCTORS.TextAreaDate('wysiwyg', 'infos_prat', 'Infos pratiques', 'Infos pratiques'),
          // new FIELD_COSTRUCTORS.TextAreaDate('wysiwyg', 'more_infos', 'Infos supplémentaires', 'Infos supplémentaires'),
          new FIELD_COSTRUCTORS.SelectMulti(
            "Attribuer une ou plusieurs thématiques",
            "event",
            "thematic",
            "Thématiques"
          ),
        ];
        selects.push("useful_doc_type", "country");
        this.title = "un nouvel événement";
        break;
      case "useful_doc":
        this.formFields = [
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "title",
            "Titre du document",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("img"),
          new FIELD_COSTRUCTORS.Select(
            "type_id",
            "Type de document",
            "useful_doc_type",
            true
          ),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "author",
            "Organisme(s) organisateur(s)",
            true,
            "exemple: organisme_1, organisme_2"
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "textarea",
            "description_short",
            "Description courte",
            "Description courte (maximum 200 caractères)",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "wysiwyg",
            "description",
            "Description complète",
            "Description complète",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("pdf"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "link",
            "Lien (si pas de PDF)"
          ),
          new FIELD_COSTRUCTORS.SelectMulti(
            "Attribuer une ou plusieurs thématiques",
            "useful_doc",
            "thematic",
            "Thématiques"
          ),
        ];
        selects.push("useful_doc_type");
        this.title = "une nouvelle documentation utile";
        break;
      case "project_appeal":
        this.formFields = [
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "title",
            "Titre de l'appel à projet",
            true
          ),
          new FIELD_COSTRUCTORS.Select(
            "type_id",
            "Type d'appel à projet",
            "project_appeal_type",
            true
          ),
          new FIELD_COSTRUCTORS.Select("pricing_id", "Tarifs", "pricing"),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "textarea",
            "description_short",
            "Description courte",
            "Description courte (maximum 200 caractères)",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "wysiwyg",
            "description",
            "Description complète",
            "Description complète",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("img"),
          new FIELD_COSTRUCTORS.Select(
            "dimension_id",
            "Dimension du projet",
            "dimension",
            true
          ),
          new FIELD_COSTRUCTORS.Country(null, "Pays porteur de l'appel"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "organization",
            "Organisme(s) organisateur(s)",
            true,
            "exemple: organisme_1, organisme_2"
          ),
          new FIELD_COSTRUCTORS.ImgPdf("pdf"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "link",
            "Lien (si pas de PDF)"
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "inputDate",
            "limit_date",
            "Date limite",
            "",
            true
          ),
          new FIELD_COSTRUCTORS.SelectMulti(
            "Attribuer une ou plusieurs thématiques",
            "project_appeal",
            "thematic",
            "Thématiques"
          ),
        ];
        selects.push("project_appeal_type", "pricing", "dimension", "country");
        this.title = "un nouvel appel à projet";
        break;
      case "formation":
        this.formFields = [
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "title",
            "Titre de la formation",
            true
          ),
          new FIELD_COSTRUCTORS.Select(
            "type_id",
            "Type de formation",
            "formation_type",
            true
          ),
          new FIELD_COSTRUCTORS.Select("pricing_id", "Tarifs", "pricing", true),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "textarea",
            "description_short",
            "Description courte",
            "Description courte (maximum 200 caractères)",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "wysiwyg",
            "description",
            "Description",
            "Description",
            true
          ),
          new FIELD_COSTRUCTORS.Radio(
            "is_online",
            "Formation en ligne  (si oui, pas besoin de saisir un lieu)",
            [
              { text: "Oui", val: "1" },
              { text: "Non", val: "0" },
            ]
          ),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "organization",
            "Organisme(s) organisateur(s)",
            true,
            "exemple: organisme_1, organisme_2"
          ),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "place",
            "Lieu de la formation"
          ),
          new FIELD_COSTRUCTORS.Country(),
          new FIELD_COSTRUCTORS.ImgPdf("img"),
          new FIELD_COSTRUCTORS.ImgPdf("pdf"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "link",
            "Lien (si pas de PDF)"
          ),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "checkbox",
            "hasNoDate",
            "Pas de date pour cette formation"
          ),
          new FIELD_COSTRUCTORS.TextAreaDate("inputDate", "debut", "Début", ""),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "inputDate",
            "end",
            "Fin (optionnel)",
            ""
          ),
          new FIELD_COSTRUCTORS.SelectMulti(
            "Attribuer une ou plusieurs thématiques",
            "formation",
            "thematic",
            "Thématiques"
          ),
        ];
        selects.push("formation_type", "pricing", "dimension", "country");
        this.title = "une nouvelle formation";
        break;
      case "job_announce":
        this.formFields = [
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "title",
            "Titre de l'annonce",
            true
          ),
          new FIELD_COSTRUCTORS.Select(
            "type_id",
            "Type de l'annonce",
            "job_announce_type",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("img"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "organization",
            "Organisme recruteur"
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "inputDate",
            "limit_date",
            "Date limite de candidature",
            "",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "textarea",
            "description_short",
            "Description courte",
            "Description courte (maximum 200 caractères)",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "wysiwyg",
            "description",
            "Description",
            "Description",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("pdf"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "link",
            "Lien (si pas de PDF)"
          ),
          new FIELD_COSTRUCTORS.SelectMulti(
            "Attribuer une ou plusieurs thématiques",
            "job_announce",
            "thematic",
            "Thématiques"
          ),
        ];
        selects.push("job_announce_type");
        this.title = "une nouvelle offre d'emploi";
        break;
      case "web_tool":
        this.formFields = [
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "title",
            "Titre de l'outil",
            true
          ),
          new FIELD_COSTRUCTORS.Select(
            "type_id",
            "Type d'outil",
            "web_tool_type",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("img"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "author",
            "Organisme(s) publicateur(s)",
            true,
            "exemple: organisme_1, organisme_2"
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "textarea",
            "description_short",
            "Description courte",
            "Description courte (maximum 200 caractères)",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "wysiwyg",
            "description",
            "Description",
            "Description",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("pdf"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "link",
            "Lien (si pas de PDF)"
          ),

          new FIELD_COSTRUCTORS.SelectMulti(
            "Attribuer un ou plusieurs objectifs à l'outil",
            "web_tool",
            "goal",
            "Objectifs"
          ),
          new FIELD_COSTRUCTORS.SelectMulti(
            "Attribuer une ou plusieurs thématiques",
            "web_tool",
            "thematic",
            "Thématiques"
          ),
        ];
        selects.push("web_tool_type");
        this.title = "un nouvel outil web";
        break;
      case "public_politic":
        this.formFields = [
          new FIELD_COSTRUCTORS.InputCheckbox("input", "title", "Titre", true),
          new FIELD_COSTRUCTORS.Select(
            "type_id",
            "Type de politique",
            "public_politic_type",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("img"),
          new FIELD_COSTRUCTORS.Select(
            "dimension_id",
            "Dimension",
            "dimension",
            true
          ),
          new FIELD_COSTRUCTORS.Country(),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "place",
            "Plus de précisions sur le lieu (optionnel)"
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "textarea",
            "description_short",
            "Description courte",
            "Description courte (maximum 200 caractères)",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "wysiwyg",
            "description",
            "Description",
            "Description",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("pdf"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "link",
            "Lien (si pas de PDF)"
          ),
          new FIELD_COSTRUCTORS.SelectMulti(
            "Attribuer une ou plusieurs thématiques",
            "public_politic",
            "thematic",
            "Thématiques"
          ),
        ];
        selects.push("public_politic_type", "dimension", "country");
        this.title = "une nouvelle politique publique";
        break;
      case "infos_divers":
        this.formFields = [
          new FIELD_COSTRUCTORS.InputCheckbox("input", "title", "Titre", true),
          new FIELD_COSTRUCTORS.Select(
            "type_id",
            "Type d'information donnée",
            "infos_divers_type",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("img"),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "wysiwyg",
            "description",
            "Description",
            "Description",
            true
          ),
          new FIELD_COSTRUCTORS.TextAreaDate(
            "textarea",
            "description_short",
            "Description courte",
            "Description courte (maximum 200 caractères)",
            true
          ),
          new FIELD_COSTRUCTORS.ImgPdf("pdf"),
          new FIELD_COSTRUCTORS.InputCheckbox(
            "input",
            "link",
            "Lien (si pas de PDF)"
          ),
          new FIELD_COSTRUCTORS.SelectMulti(
            "Attribuer une ou plusieurs thématiques",
            "infos_divers",
            "thematic",
            "Thématiques"
          ),
        ];
        selects.push("infos_divers_type");
        this.title = "une nouvelle information";
        break;
    }

    selects.forEach((select) => {
      const url = apiURL(select, "lire", "");
      ajax(
        url,
        "GET",
        null,
        (res: any) => {
          this[select] = JSON.parse(res).records;
        },
        false
      );
    });
  }
}
