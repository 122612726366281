import { Component, OnInit } from "@angular/core";
import { AVANTAGES_ADHESION_PHYSIC_PDF_NAME } from "../../../providers/global";
import { ajax, apiURL } from "../../../providers/helpers";
import { getStoredUser } from "../../../providers/user-service";

@Component({
  selector: "app-documentary-center",
  templateUrl: "./documentary-center.component.html",
  styleUrls: ["./documentary-center.component.css"],
})
export class DocumentaryCenterComponent implements OnInit {
  avantages_adhesion_physic_pdf_name = AVANTAGES_ADHESION_PHYSIC_PDF_NAME;

  user;
  pdf_id;
  displayLoginPopup = false;

  constructor() {}

  showPdf(pdfName) {
    const url = apiURL("pdf", "read_one_id", "");

    ajax(
      url,
      "POST",
      { name: pdfName },
      (res) => {
        let response: any = res;
        response;
        response = JSON.parse(response);
        this.pdf_id = response.records.id;
      },
      false
    );
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.user = getStoredUser();
  }
}
