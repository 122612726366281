import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  faEnvelope,
  faPhone,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { ajax, apiURL } from "../../../providers/helpers";

@Component({
  selector: "app-popup-adherent-details",
  templateUrl: "./popup-adherent-details.component.html",
  styleUrls: ["./popup-adherent-details.component.css"],
})
export class PopupAdherentDetailsComponent implements OnInit {
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faTimes = faTimes;

  activityDomains = [];
  thematics = [];
  adherent: any;

  showPhone = false;
  pdf_id = null;

  @Input() userId: string;
  @Output() close = new EventEmitter();

  constructor() {}

  closePopup() {
    this.close.emit();
  }

  loadItems(table_name, method, propName) {
    const url = apiURL(table_name, method, this.userId);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        // console.log(res, table_name, method, this.userId);
        this[propName] = JSON.parse(res).records;
        if (propName === "adherent") {
          // console.log(this.adherent);
        }
        if (propName === "adherent" && this.adherent.img_data) {
          this.adherent.img_data =
            "data:image/jpg;base64," + this.adherent.img_data;
        }
      },
      false
    );
  }

  loadActivityDomains() {
    this.loadItems("user_activity_domain", "lire", "activityDomains");
  }

  loadThematics() {
    this.loadItems("user_thematic_sub", "lire", "thematics");
  }

  loadUser() {
    this.loadItems("user", "read_one_adherent", "adherent");
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.loadUser();
    this.loadActivityDomains();
    this.loadThematics();
  }
}
