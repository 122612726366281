import { Component, OnDestroy, OnInit } from "@angular/core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";

@Component({
  selector: "app-annuaire",
  templateUrl: "./annuaire.component.html",
  styleUrls: ["./annuaire.component.css"],
})
export class AnnuaireComponent implements OnInit, OnDestroy {
  faSearch = faSearch;

  displayFiltersPopup = false;
  activeFilters = false;
  selectedAdherentId = null;

  users: any[];

  table: any;
  eventsSubject: Subject<void> = new Subject<void>();

  navDataChanged;

  filterTables = [
    { name: "Thématiques d'intérêt", val: "thematic_sub" },
    { name: "Organismes de rattachement", val: "organization" },
    { name: "Catégories d'organisme", val: "organization_category" },
    { name: "Compétence principale", val: "competence" },
    { name: "Domaine(s) d'activité", val: "activity_domain" },
    { name: "Pays", val: "country" },
  ];

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.navDataChanged = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.table) return;

        const paginationMethod = this.table.method + "";
        this.table.method = "false";

        setTimeout(() => {
          this.table.method = paginationMethod;
        }, 400);
      }
    });
  }

  loadItems(evt) {
    this.users = [];
    this.users = evt.records;
  }

  filterAdherents({ prop_values, prop_name }) {
    this.table.method = "false";
    this.table.filter_prop_name = prop_name;
    this.table.filter_prop_values = prop_values
      .map((item) => item.id)
      .join(",");
    this.table.SFSE_filter_page = "annuaire";

    setTimeout(() => {
      this.table.method = "read_adherent";
      this.activeFilters = true;
    }, 400);
  }

  refreshPagination() {
    // console.log('ok - 2');
    window.sessionStorage.removeItem("SFSE_filter_prop_values");
    window.sessionStorage.removeItem("SFSE_filter_prop_name");
    window.sessionStorage.removeItem("SFSE_filter_page");
    this.table.filter_prop_name = null;
    this.table.filter_prop_values = null;
    const paginationMethod = this.table.method + "";
    this.table.method = "false";
    setTimeout(() => {
      this.table.method = paginationMethod;
    }, 400);
  }

  cancelFilter() {
    // console.log('ok - 1');
    this.activeFilters = false;
    this.refreshPagination();
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.activeFilters =
      window.sessionStorage.SFSE_filter_prop_values &&
      window.sessionStorage.SFSE_filter_page === "annuaire"
        ? true
        : false;
    this.activatedRoute.params.subscribe((data) => {
      this.table = {
        name: "user",
        method: "read_adherent",
        active_filters: this.activeFilters,
      };
    });
  }

  ngOnDestroy() {
    if (this.navDataChanged) {
      this.navDataChanged.unsubscribe();
    }
  }
}
