import { Component, OnInit } from "@angular/core";
import { ajax, apiURL } from "../../../providers/helpers";

@Component({
  selector: "app-congress-signup",
  templateUrl: "./congress-signup.component.html",
  styleUrls: ["./congress-signup.component.css"],
})
export class CongressSignupComponent implements OnInit {
  congress = {
    closed: null,
    pricing_limit_date: null,
    title: "",
    program_desc: "",
  };
  popupText = "";
  showPopup = false;
  displaySignup = false;

  constructor() {}

  displayPopup() {
    if (this.congress.closed == "1") {
      this.popupText = "Les inscriptions sont closes pour ce congrès !";
    } else if (!this.congress.pricing_limit_date) {
      this.popupText = "Les inscriptions ne sont pas encore ouvertes !";
    }

    this.showPopup = true;
  }

  ngOnInit() {
    window.scroll(0, 0);

    const url = apiURL("congress", "read_active", "");

    ajax(
      url,
      "GET",
      null,
      (res) => {
        let response: any = res;
        this.congress = JSON.parse(res).records || {};
      },
      false
    );
  }
}
